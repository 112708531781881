import React, { Component } from "react"
import "./style.scss"

export const ModalContext = React.createContext({
  component: null,
  props: {},
  showModal: () => {},
  hideModal: () => {},
})

export const ModalConsumer = ModalContext.Consumer

export class ModalProvider extends Component {
  constructor() {
    super()
    this.keypress = ""
  }

  keypressEscap = event => {
    if (event.key === "Escape") this.hideModal()
  }

  showModal = (component, props = {}) => {
    document.querySelector("body").classList.add("is-hidden")
    this.keypress = document.addEventListener("keydown", this.keypressEscap)

    this.setState({
      component,
      props,
    })
  }

  hideModal = () => {
    document.querySelector("body").classList.remove("is-hidden")
    this.keypress = document.removeEventListener("keydown", this.keypressEscap)

    this.setState({
      component: null,
      props: {},
    })
  }

  state = {
    component: null,
    props: {},
    showModal: this.showModal,
    hideModal: this.hideModal,
  }

  render() {
    return (
      <ModalContext.Provider value={this.state}>
        {this.props.children}
      </ModalContext.Provider>
    )
  }
}

export const ModalContainer = ({ onRequestClose, ...otherProps }) => (
  <div
    className={`c-modal ${otherProps.className ? otherProps.className : ""}`}
  >
    {onRequestClose ? (
      <>
        {!otherProps.layer ? (
          <div className="c-modal__content">
            <div className="c-modal__head">
              {otherProps.headContent}
              <div
                onClick={onRequestClose}
                onKeyPress={onRequestClose}
                role="button"
                className="c-modal__exit"
                tabIndex="0"
              />
            </div>
            {otherProps.children}
          </div>
        ) : (
          otherProps.children
        )}
        <div
          onClick={onRequestClose}
          onKeyPress={onRequestClose}
          role="button"
          className="c-modal__background"
          tabIndex="-1"
        ></div>
      </>
    ) : (
      <div className="c-modal__content">{otherProps.children}</div>
    )}
  </div>
)
