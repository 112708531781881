import React, { Component } from "react"
import { langs } from "../../i18n/languages"
import { withTrans } from "../../i18n/withTrans"
import { navigate } from "gatsby"
import "./style.scss"

class LanguageMenu extends Component {
  constructor(props) {
    super(props)

    const { langKey } = props

    this.displayLangs = this.displayLangs.bind(this)
    this.hideLangs = this.hideLangs.bind(this)
    this.mobileOnClick = this.mobileOnClick.bind(this)

    this.menu = ".c-menu"
    this.menuLangs = ""
    this.currentLang = langKey && langKey.length > 0 ? langKey : "en"

    this.state = {
      currentLang: this.currentLang,
      displayMenu: false,
      animated: false,
    }
  }

  handleChange = async event => {
    const { i18n } = this.props
    // const value = event.target.value
    const value = event.currentTarget.getAttribute("data-value");

    if (typeof window !== "undefined") {
      let matchedLang = langs.find(lang => lang.nodeLocale === value)
      let newPath = value

      if (matchedLang) {
        newPath = matchedLang.value
      }

      if (i18n.language === value) return;

      if (value === "en-US") {
        newPath = "/"
      }

      // if (i18n.language !== "en-US" && value !== "en-US") {
      //   newPath = value
      // } else if (i18n.language !== "en-US" && value === "en-US") {
      //   newPath = ``
      // } else if (i18n.language === "en-US" && value === "en-US") {
      //   newPath = `${pathname}`
      // } else {
      //   newPath = `/${value}/`
      // }

      await navigate(newPath)
      await i18n.changeLanguage(value)
    }
  }

  displayLangs = () => {
    document.querySelector(".c-language").classList.add("c-language--active")
  }

  hideLangs() {
    document.querySelector(".c-language").classList.remove("c-language--active")
  }

  mobileOnClick() {
    const { displayMenu } = this.state

    if (window.innerWidth <= 768) {
      if (displayMenu) {
        this.displayLangs()
      } else {
        this.hideLangs()
      }
    }
  }

  mobileLinkClick() {
    if (window.innerWidth <= 768 && document.querySelector(".c-menu")) {
      document.querySelector(".c-menu").style.opacity = 0
    }
  }

  render() {
    const { langKey } = this.props
    const { currentLang } = this.state

    const activeLanguage = langs.find(lang => lang.value === currentLang)
    const unactiveLanguages = langs.filter(lang => lang.value !== currentLang)

    return (
      <div
        onClick={this.mobileOnClick}
        onKeyPress={this.displayLangs}
        onMouseEnter={this.displayLangs}
        onMouseLeave={this.hideLangs}
        // onBlur={this.hideLangs}
        className="c-language"
        tabIndex={0}
        role="menu"
      >
        {[activeLanguage, ...unactiveLanguages].map(
          lang =>
            lang !== undefined && (
              <div
                onClick={this.handleChange}
                onKeyPress={this.handleChange}
                data-value={lang.nodeLocale}
                key={lang.text}
                value={lang.value}
                className={
                  lang.value === currentLang
                    ? "c-language__elem c-language__elem--active"
                    : "c-language__elem"
                }
                role="menuitem"
                tabIndex={0}
              >
                {lang.value === langKey ? (
                  <span role="img" aria-label={`language ${lang.text}`}>
                    {lang.icon}
                  </span>
                ) : (
                  <span role="img" aria-label={`language ${lang.text}`}>
                    {lang.icon}
                  </span>
                )}
              </div>
            )
        )}
        <div />
      </div>
    )
  }
}

export default withTrans(LanguageMenu)
