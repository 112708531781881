/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "components/header"
import Footer from "components/footer"
import { ModalProvider } from "components/modalContainer"
import ModalRoot from "components/modalContainer/modalRoot"
import { langs } from "../i18n/languages"
import "./layout.css"
import { withTrans } from "../i18n/withTrans"
import { useEffect } from "react"

const getCurrentLangKey = (langs, defaultLangKey, url) => {
  if (!url) return defaultLangKey

  const langKey = url.split("/")[1]
  
  if (!langKey) return defaultLangKey

  let currentLangKey = langs.filter(lang =>
    lang.value.match(new RegExp(langKey))
  )

  return currentLangKey[0] ? currentLangKey[0].value : defaultLangKey
}

const Layout = ({ children, location, i18n }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      allContentfulFooter(filter: { node_locale: { eq: "en-US" } }) {
        footerContent: nodes {
          contactPhrase
          socialNetworks {
            socials {
              action
              color
              name
              url
            }
          }
        }
      }
    }
  `)

  const url = location?.pathname
  const langKey = getCurrentLangKey(langs, "en", url)

  useEffect(() => {
    if (!i18n.language) {
      if (typeof window !== "undefined") {
        let matchedLang = langs.find(lang => lang.value === langKey)

        if (matchedLang) {
          i18n.changeLanguage(matchedLang.value)
        }
      }
    }
  }, [i18n, langKey])

  const {
    site,
    allContentfulFooter: { footerContent },
  } = data

  let contentF = footerContent[0] ? footerContent[0] : {}

  return (
    <>
      <ModalProvider>
        <Header siteTitle={site.siteMetadata.title} langKey={langKey} />
        <div>
          <main>{children}</main>
        </div>
        <Footer data={contentF} />
        <ModalRoot />
      </ModalProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default withTrans(Layout)
