import React from "react"
import "./style.scss"
import { withTrans } from "../../i18n/withTrans"

const Footer = ({ data: { contactPhrase, socialNetworks }, t }) => {
  return (
    <footer id="footer">
      <div className="container">
        <p className="ft-h footer__catchphrase">{t("footer.contactPhrase")}</p>
        {socialNetworks && socialNetworks.socials && (
          <div className="footer__socials">
            {socialNetworks.socials.map(sn => {
              let extraProps = {}
              if (sn.action === "external") {
                extraProps.rel = "noreferred noopener"
                extraProps.target = "_blank"
              }

              return (
                <div className="footer__socials__item" key={`lf${sn.name}`}>
                  <a
                    href={sn.url}
                    className="ft-white link ft-h"
                    {...extraProps}
                  >
                    {sn.name}
                  </a>
                  {sn.action === "external" && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                      data-credit="Icon by useiconic.com from the Noun Project"
                    >
                      <polygon points="9.75 .688 4.094 6.344 15.407 6.344 .563 21.188 4.813 25.438 19.657 10.594 19.657 21.907 25.313 16.251 25.313 .688" />
                    </svg>
                  )}
                </div>
              )
            })}
          </div>
        )}
      </div>
    </footer>
  )
}

export default withTrans(Footer)
