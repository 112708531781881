module.exports = {
  defaultLangKey: "en",
  langs: [
    {
      value: "en",
      nodeLocale: "en-US",
      text: "En",
      icon: "🌍",
      active: true,
    },
    {
      value: "fr",
      nodeLocale: "fr",
      text: "Fr",
      icon: "🇫🇷",
      active: false,
    },
    // {
    //   value: "ja",
    //   nodeLocale: "ja",
    //   text: "日本",
    //   icon: "🇯🇵",
    //   active: false,
    // },
  ],
}
