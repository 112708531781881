import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import "./style.scss"
import LanguageMenu from "../LanguageMenu"

const Header = ({ siteTitle, langKey }) => {
  const homeLink = `/${langKey}/`.replace(`/en/`, "/")

  return (
    <header>
      <div className="container container--offset">
        <Link to={homeLink} className="title ft-dark-grey">
          Stephen richard.
        </Link>
        {/* <a href="#about" className="menu-item link">
        About
      </a> */}
        <div className="header-role-lang">
          <p className="subtitle ft-light-grey">Front-end developer</p>
          <LanguageMenu langKey={langKey} />
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
